import {
  GridItemResponsiveType,
} from "react-schematic";
/**
 * Grid items data
 */
type GridItemsData = GridItemResponsiveType & {
  text: string; title: string; id: string; name: string; pathname: string;
};
export const gridItemsData: GridItemsData[] = [
  {
    id: "seal",
    title: "On $SOL",  
    text: "Seal template",
    name: "blog",
    pathname: "/seal/"
   // The gridArea useful behaviour moves a grid items position in the list 
   // based upon the view size and grid items setting sm/md/lg... etc.
   // gridArea: { md: "2/2/span 1/span 1"}
  },
  {
    id: "elegal",
    title: "On $ETH",
    text: "eLegal site",
    name: "blog",
    pathname: "/elegal/"
    // gridArea: { md: "2/2/span 1/span 1"} 
      
  },
  {
    id: "smart-contract-interact",
    title: "Web Interaction",
    text: "Smart Contract",
    name: "blog",
    pathname: "/contract-interact/"
  },
  {
    id: "memetoday",
    title: "More NFT fun",
    text: "MemeToday.Cafe",
    name: "blog",
    pathname: "/memetoday-cafe/"
  },
  {
    id: "raffle",
    title: "Thirdweb",
    text: "Raffle App",
    name: "blog",
    pathname: "/raffle/"
   // gridArea: "1/1/span 1/span 1"
  },
  {
    id: "voting",
    title: "For Members",
    text: "Voting App",
    name: "blog",
    pathname: "/voting-app/"
  },
  {
    id: "predict",
    title: "App",
    text: "Sentence Predict",
    name: "blog",
    pathname: "/predict/"
   // gridArea: "1/1/span 1/span 1"
  },
  {
    id: "catmeme",
    title: "Meme",
    text: "Cat/Dog",
    name: "blog",
    pathname: "/cat-meme/"
  }
];


